	.navbar-static-top{
		// background-color: #023641;
		color:rgba(254,254,254,.6);
	}

	.nav{
		&>li>a {
			color:rgba(254,254,254,.6);
		}

		.open>a{
			background: none;
		}
	}

	#white-nav-bar {
		display: none;
	}

	.navbar-nav{		
		li{
			border-color:transparent;
			border-left:1px solid #eee;			
			border-right:1px solid #eee;

			a{
				&:hover{
					text-decoration: underline;
				}
			}
		}
		.conference {			
			text-transform: uppercase;
			a{
				font-weight: bold;
				// color:#41499e !important;						
			}
		}
	}
	
body.top-navigation {
	.navbar-nav{
		.dropdown-menu{
			box-shadow: 0 5px 8px -2px rgba(86,96,117,0.3);
		}
	}

	.nav > li > a{
		padding:20px 10px;

		@media (min-width: 992px) {
			padding:20px 20px;
		}
	}

	.navbar-header{
		margin: 6px 20px 0px;		
	}

	.navbar-brand {
		background: none;
		padding:0;

		@media (min-width: 992px) {
			width: 140px;
		}

		img{
			max-width: 100%;
			max-height: 100%;
		}
	}

	.wrapper.wrapper-content{
		padding:20px 0;
	}

	.nav > li a:hover, .nav > li a:focus {
		color: #676a6c;	
	}
}

.navbar-static-top {
	position: fixed;
	width: 100%;
	z-index: 103;
	height: @header-height;
	border-bottom: 1px solid #d2d3d4 !important;

	.navbar-brand {
		padding:7px;
		height: @header-height;
		img { max-height: 100%; }
	}
}

#side-menu {
	margin-top: 14px;

	.mini-navbar & li a{
		text-align: center;
	}

	.nav-header {
		.fa {
			width:14px;
			text-align: center;
		}
	}
}

.navbar-static-side {
	position: fixed;
    width: 220px;
    z-index: 101;
    height: 100%;
    padding-top: @header-height;
}

@media (max-width: 768px) {
	.body-small .navbar-static-side {
		display: none;
		width: 70px;
	}

	.body-small.mini-navbar .navbar-static-side {
	    display: block;
	}
}

#sidemenu-toggle {
	padding: 4px 7px;
	margin-top: 16px;
	background: none;
    border: none;
	
	i {
		font-size: 20px;
	}
}

.navbar {
	margin-bottom: 0;
}