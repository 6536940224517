.form-group {
	.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
		margin-bottom:15px;

		&:last-child {
			margin-bottom:0;
		}

		@media (min-width: @screen-sm-min) {
			margin-bottom: 0;
		}
	}
}

.venueSearchResults{
	background-color: rgb(255, 255, 255);
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;

	ul{
		margin:0;
		padding:0;
	}

	ul, li{
		list-style: none;
	}

	li{
		display: block;
		padding:14px 14px;
		border-top:1px solid #e6e6e6;

		.fa{
			font-size: 25px;
			vertical-align: middle;
			margin-right: 8px;
			color: #aaa;
		}

		&:hover{
			background: #f9f9f9;
			cursor: pointer;
		}

		.name{
			font-weight: bold;
			color:#333;
		}
	}

	.new-venue{
		padding:7px 14px;
		display: block;
		background-color: #182B56;
		color: #fff;
		opacity: 0.8;

		&:hover{
			color:#fff;
			text-decoration: none;
			opacity: 1;
		}
	}
}

.attendeeSearchResults {
	.info {
		display: inline-block;
	}
	li .fa {
		vertical-align: top;				
	}
}

.selected-venue{
	.fa{
		font-size: 40px;
		display: inline-block;
		vertical-align: top;
		margin-right: 14px;
	}

	.info{
		display: inline-block;
		font-size: 16px;
		margin-bottom: 14px;
	}

	.name{
		font-weight: bold;
		color:#333;
	}
}

.meetings-request{
	.dateTime{
		width: 50%;
		display: inline-block;
	}
	.dateAMPM{
		width: 30%;
		display: inline-block;
	}

	li.search-field input[type=text]{
		width:auto !important;
	}
	
	.meeting-type-field{
		margin-bottom: 0;
	}

	.selected{
		background-color: #E2F2F4 !important;
		&:hover, &:active, &:focus{
			background-color: #E2F2F4 !important;
		}
	}

	.trained-status {
		font-weight: bold;
		color: #56BB00;
	}

	.email {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 20em;
	}

	.not-trained{
		opacity: 0.25;

		cursor: not-allowed;

		.trained-status {
			color: #BB0000;
		}
	}

	.speaker-select{
		.control-label{
			padding-top:0;
		}

		.spinner {			
			right: 0;
			left: inherit;

			& > div {
				width: 3px;
				height: 14px;
			}
		}

		.conflict-message {
			color: #d48900;
		}
	}

	.date {
		.spinner {
			position: relative;
			width: 14px;
			& > div {
				width: 3px;
				height: 14px;
			}
		}
	}

	.table-responsive{
		height: 300px;
		overflow-y: scroll;
		border-bottom:1px solid #999;
	}

	.btn-group{
		.btn-default{
			background-color: #eee;
			color:#999;
			font-weight: bold;

			&:hover{
				color:#666;
			}
		}
		.active{
			color:#333;
			&:hover{
				color:#333;
			}
		}
	}

	.time-select{
		select{
			float:left;
			display: inline;
			margin-right: 14px;
		}
	}

	.kol-checkbox{
		font-size: 20px;
		text-align: center;
		width: 50px;
	}

	.kols-list{
		td{
			vertical-align: middle;
		}
	}

	label.radio{
		display: inline-block;
		font-weight: normal;
		margin-left:20px;

		& + label.radio {
			margin-left:40px;
		}
	}

	label.checkbox {
		display: block;
		font-weight: 400;
		margin-left: 20px;
	}

	label.question{
		font-size: 16px;
		vertical-align: middle;
		width: 370px;
		text-align: right;
	}

	.input-group.date{
		.fa-calendar{
			color:#000;
		}
		.dropdown-menu{
			background-color: #F5F5F5;
			padding: 5px;
			&:focus, &:active{
				outline:none;
			}
			.btn{
				margin-bottom: 0;
				&.active{
					background-color: rgb(240, 105, 55);
					color: #fff;

					.text-info{
						color:#fff;
					}
				}
			}
			.text-muted{
				color:#D2D2D2;
			}
			.text-info{
				color:#CF6E00;
			}
		}

		.btn-default{
			background-color: #fff;
			border-color: #c2c2c2;
			color: #000;
		}
	}

	.timepicker-label{
		float: left;
		width: auto;
		font-weight: normal;
		margin-top: 25px;
		margin-right: 10px;
	}

	.timepicker-container{
		@media (max-width: @screen-md-max) {
			margin-top:20px;
		}
	}

	.timepicker{
		margin-top: -10px;

		.btn{
			margin:0;
		}
	}

	attendee {
		.info {
			margin-bottom: 0;
		}
		.reset {
			font-size: 12px;
			font-weight: normal;
			margin:0;
		}
	}

	.reset {
		text-decoration: underline;
		margin-top: 8px;
		display: inline-block;

		&:hover{
			color:#182B56;
		}
	}

	#attendees {
		.remove-attendee {
			position: absolute;
			font-size: 20px;
			margin-top: 8px;
			color: #925555;
			z-index: 999;
		}		

		.attendee-container + .attendee-container {
		    border-top: 1px solid #f0f0f0;
		    padding-top: 24px;
		}		
	}

	#box-virtual-program {
		.well, .alert {
			margin-bottom: 0;
			p {
				margin-bottom: 14px;
			}
		}	

		.well {
			font-size: 16px;
		}	

		.ibox-tools {
			a, a:visited {
				color: #280091;
				text-decoration: underline;
			}
		}
	}

	#box-speakers{
		.group-meeting-details {
			& > div {
				& + div {
					border-top: 1px dashed #999;
					padding-top: 40px;
				}
			}
		}
	}
} //.meetings-request

.meetings-detail{
	dl{
		display: inline-block;
		width: 100%;
	}

	dt{
		display: inline-block;
		width: 40%;
		float: left;
		text-align: right;
		clear: both;
		padding-right: 14px;
		margin-bottom: 7px;
	}

	dd{
		display: inline-block;
		float: left;
		width: 60%;
		margin-bottom: 7px;
	}

	li {
		margin-bottom: 7px;
	}	

	h2{
		font-size: 20px;
	}

	.allocation-info{
		padding-bottom: 30px;
	}

	.approval-actions{
		margin-top: 10px;

		.btn{
			margin-left: 4px;
		}
	}

	.highlight {
		background: #F9E4A2;
		padding-top: 7px;
		padding-bottom: 7px;
	}

	.hr-line-dashed {
		clear: both;
	}

	.group-meeting-details {
		.col-sm-6{
			margin-bottom: 7px;
		}
	}
}

.virtual-programs-modal-body {	
	background: #f3f3f4;
	padding:20px 0 30px;
	.program {
		cursor: pointer;
		background: #fff;
		font-size: 16px;
		.program-container{
			padding: 14px 0;	
			color:#676a6c;
			margin:0 20px;
			overflow: hidden;
		}
		
		&+.program {
			.program-container {
				border-top:1px solid #f3f3f4;				
			}
		}
	}

	.icon {
		font-size: 18px;
		padding-top: 7px;
		text-align: right;
		color:#280091;
	}
}

.register-attendee-body {
	padding:20px 0 30px;
}