.events-sidebar-left{
	.make-md-column(4);
	.make-md-column-pull(8);

	.affix{
		width:33.333333%;
	}

	.meetings-filters{
		margin-bottom: 0 !important;
		.col-sm-3{
			width: 100%;
			margin-bottom: 14px;
		}
	}
}

.events-sidebar-right{
	.make-md-column(8);
	.make-md-column-push(4);
	padding-left: 0;
}	

.wrapper-events{
	padding-top:100px;
	
	hr{
		border-color: #ddd;
	}
}

.meeting-request {
	// position: absolute;
	// bottom:0;
	// right:15px;
}
#calendar-container{
	.affix{
		// top:0;
		// right:15px;
		// padding-right: 0;
		// padding-top:20px;
		// -webkit-transform: translateZ(0);
	}
}

.fc-event{
	&:hover, &:focus{
		outline:none;
		color:#fff;
	}
}

.fc-week{
	td.fc-day.fc-widget-content {
		position: relative;

		&:after {
		  content: '';
		  width: 100%;
		  position: absolute;
		  top: -19px;
		  background-color: transparent;
		  height: 20px;
		  cursor: pointer;
		}
	}
}

@search-bar-top: 30px;

#meetings-filter-container {
	margin-top:@search-bar-top;
}

.meetings-search{
	display: table;
	padding:0;

	.btn{
		&:focus{
			outline:none;
		}
	}
}

.page-heading {
	.meeting-request{
		margin-top: @search-bar-top;
	}	
}

.query-container{
	position: relative;
	.clear-query{
		position: absolute;
		right: 6px;
		top: 6px;
		z-index: 10;
		font-size: 18px;
		color:#bbb;
	}
}

.table-cell{
	display: table-cell;
	vertical-align: top;
}

.meetings-filter{
	display: inline-block;
}

.meetings-filters{
	max-height: 999px;
	overflow: hidden;
	padding:20px 0;
	opacity: 1;
	background:#fff;
	position: absolute;
	z-index: 1000;
	box-shadow: 0 5px 10px -3px rgba(0,0,0,0.3);
	border-radius: 5px;

	.clear-filters{
		margin-left:4px;
		font-weight: normal;

		a{
			
		}
	}

	& > div {
		margin: 7px 0;
	}

	.filter-label{
		font-weight: bold;

		@media (max-width: 767px) {
			margin-top: 14px;
		}
	}

	.filter-checkbox{
		display: inline-block;
		width: 15px;
		margin-left: -20px;
	}

	ul{
		list-style: none;
		margin:0;
		padding: 0 0 0 6px;
	}

	li{
		line-height: 1em;
		padding:4px 0 0 20px;
		a{
			color:#333;
		}
	}

	&.ng-hide-add.ng-hide-add-active, 
	&.ng-hide-remove.ng-hide-remove-active {
		transition: all 0.15s ease-in-out;
	}
	&.ng-hide {
		// max-height: 0;
		opacity: 0;
	}
}

.label.label-submitted { background-color: @color-submitted; color: #fff}
.label.label-pending { background-color: @color-pending; color: #fff}
.label.label-approved { background-color: @color-approved; color: #fff}
.label.label-cancelled { background-color: @color-cancelled; color: #fff}
.label.label-denied { background-color: @color-denied; color: #fff}
.label.label-completed { background-color: @color-completed; color: #fff}
.label.label-closed { background-color: @color-closed; color: #fff}
.label.label-confirmed { background-color: @color-confirmed; color: #fff}
.label.label-in-progress { background-color: @color-in-progress; color: #fff}

@keyframes flashHighlight {
	0% { box-shadow: 0 0 0px 0px rgba(0,0,0,0); }
	20% { box-shadow: 0 0 6px 3px rgba(250,120,65,1) }
	90% { box-shadow: 0 0 6px 3px rgba(250,120,65,1) }
	100% { box-shadow: 0 0 0px 0px rgba(0,0,0,0); }
}
.meeting{
	margin-bottom: 15px;
	position: relative;
	// transition: box-shadow 0.5s;
	box-shadow:0;
	border-left: 7px solid;

	.ibox-tools .label{ color: #fff;}
	.ibox-title{
		padding: 7px 15px 7px;
		height: auto;
		.clearfix;
	}

	&.selected{		
		animation: flashHighlight 4s forwards;
	}

	//Status colors
	&.submitted{
		border-color: @color-submitted;
		.ibox-tools .label { background-color: @color-submitted; }
	} 
	&.pending{
		border-color: @color-pending;
		.ibox-tools .label { background-color: @color-pending; }
	} 
	&.approved{
		border-color: @color-approved;
		.ibox-tools .label { background-color: @color-approved; }
	} 
	&.cancelled{
		border-color: @color-cancelled;
		.ibox-tools .label { background-color: @color-cancelled; }
	} 
	&.denied{
		border-color: @color-denied;
		.ibox-tools .label { background-color: @color-denied; }
	} 
	&.completed{
		border-color: @color-completed;
		.ibox-tools .label{ background-color: @color-completed; }
	}
	&.closed{
		border-color: @color-closed;
		.ibox-tools .label{ background-color: @color-closed; }
	}
	&.confirmed{
		border-color: @color-confirmed;
		.ibox-tools .label{ background-color: @color-confirmed; }
	}
	&.in-progress {
		border-color: @color-in-progress;
		.ibox-tools .label{ background-color: @color-in-progress; }
	}

	.meeting-type{
		// vertical-align: middle;
		// font-size: 16px;
		// margin-right: 4px;
		// color:#aaa;
		color:rgba(255,255,255,0.8);
		position: absolute;
		top: 5px;
		left: -17px;
		.fa-calendar{
			margin-left:1px;
		}
	}

	.meeting-date{
		float:left;		
	}

	.meeting-description{		
		padding:4px 0;
		max-height: 3em;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 13px;

		.badge { 
			vertical-align: bottom;
		}

		em {
			font-size: 12px;
			color: #adadad;
		}
	}
	.meeting-description-link{
		display: inline-block;
		margin: 4px 0 7px;
		text-decoration: underline;
		font-size: 12px;
	}

	.side-kols{
		overflow: hidden;
	}

	.meeting-kols{
		margin-top:14px;

		@media (min-width: 768px) {
			margin-top:0;
			border-left: 1px solid #ddd;
			padding-left: 14px;
		}			
	}	

	.meeting-location{
		margin-top: 4px;
	}

	.meeting-attendees{
		margin-top:0;
	}

	.meeting-title{
		color:#333;
		font-size: 18px;
		display: block;
		margin-bottom: 0;
		font-weight: bold;
		line-height: 1.2em;

		&:hover{
			text-decoration: underline;
			color:inherit;
		}
	}

	.icon-container{
		width:14px;
		text-align: left;
		display: inline-block;
	}

	ul{
		margin:0;
		padding:0;
		list-style: none;

		li{
			&+li{
				margin-top:10px;
			}
		}
	}

	.kol{
		a{
			margin-right: 7px;			
			.img-circle{
				width: 40px;
			}
		}		
	}	
}

.kol-avatar{
	img{
		width: 40px;
	}		
}

.search-container{
	margin-bottom:18px;
}

.meetings-detail{
	.control-label{
		padding:0;
	}

	.title-action {
		.btn {
			margin-top:7px;
		}
	}

	.kols-list{
		.kol{
			padding:10px 0;
			border-top: 1px solid #e7eaec;

			&:nth-child(odd){
				background-color: #f9f9f9;			
			}

			&:first-child{
				// border-top:none
			}
		}
	}

	.show-bio{
		@media (max-width: 767px) {
			margin-top:14px;
		}
	}

	.kol-bio{
		border-top:1px dashed #f0f0f0;
		padding-top:14px;
		margin-top:14px;
	}

	.meeting-description{
		white-space: pre-wrap;
	}
}

.request-confirmation{
	h3{
		margin-bottom:14px;
	}
	.control-label{
		padding-top:0;
	}
}

.fc-day-number{
	&:hover{
		cursor: pointer;
	}
}

.events-sidebar-left{
	// min-height: 1200px;
	// padding-bottom: 300px;

	.affix{
		top:0;
		left:0;
		padding:20px 15px 0;
		z-index: 10;
		background: #F3F3F4;
		background: rgba(243, 243, 244, 0.9);
		box-shadow:0 13px 10px -15px #000;

		& + div{
			margin-top:71px;
		}
	}

	&.filter-active{
		.affix{
			& + div{
				margin-top:214px;
			}
		}
	}
}

#calendar-button{
	position: fixed;
	bottom:10px;
	right: 10px;
}

.action-button{
	width:20px;
	height:20px;
	display: block;
	border-radius: 50%;
	border:20px solid;
	color:#fff;
	font-size: 20px;
	position: relative;

	i{
		position: absolute;
		top:-10px;
		left:-10px;
	}

	&:active, &:hover{
		color:rgba(255,255,255,0.7);
	}
}

@media (max-width: @screen-md) {
	 #calendar-container{
	 	visibility: hidden;
	 	position: absolute;
	 	left:-5000em;
	 }

	 .events-sidebar-left .affix{
	 	width: 100% !important;
	 	padding-top:14px;
	 }

	 body > #calendar-container{
	 	position: fixed;
	 	top:0;
	 	left:0;
	 	right:0;
	 	bottom:0;
	 	margin:0 auto;
	 	background: rgba(0,0,0,0.5);
	 	z-index: 2000;
	 	visibility: visible;
	 	padding-top:20px;
	 	padding-left:15px;

	 	.action-button{
	 		position: fixed;
	 		bottom: 10px;
	 		right: 10px;
	 		background-color: #B70101;
	 		border-color: #B70101;
	 		z-index: 100;

	 		i{
	 			top: -13px;
	 			left: -9px;
	 			font-size: 24px;
	 		}
	 	}
	 }
}

.sb-programs.list {
	.events-sidebar-right {
		display: none;
	}
	.events-sidebar-left {		
		float: none;
		width: 100%;
		right: auto;
	}
}