//Font Awesome
@import "font-awesome/font-awesome.less";

//Plugins
@import "plugins/animate.less";
@import "plugins/chosen.min.less";

//Bootstrap
// @import "bootstrap/bootstrap.less";
@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";
@import "bootstrap/alerts.less";
@import "bootstrap/wells.less";

// Variables, Mixins
@import "variables.less";
@import "mixins.less";

// INSPINIA Theme Elements
@import "typography.less";
@import "navigation.less";
@import "top_navigation.less";
@import "buttons.less";
@import "badgets_labels.less";
@import "elements.less";
@import "sidebar.less";
@import "base.less";
@import "pages.less";

// INSPINIA Skins
// @import "skins.less";

// Media query style
@import "media.less";

// Custom style
// Your custom style to override base style
@import "custom.less";
@import "print.less";

@-ms-viewport{ width: auto !important; } 








