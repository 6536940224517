.change-password{
	.message{
		.alert;
		.alert-danger;

		ul{
			margin-top: -14px !important;
			list-style: none;
			padding:0 !important;
		}

		li{
			line-height: 1.2em;

			& + li {
				margin-top: 10px;
			}
		}
	}	
}

.target-map {
	padding:0!important;

	& > .row {
		padding-left:15px;
		padding-right:15px;
	}

	#frameContainer{
		z-index: 100;
		position: relative;
		margin:0 -15px;

		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			top:0;
			left:0;
		}
	}
}

.sb-metrics {
	.loading-overlay {
		background: #f3f3f4;
		opacity: 0.9;
	}
}

.resources {
	.forum-container {
		.btn {
			float:none!important;
			margin:7px 0 0!important;
		}

		.forum-icon {
			@media (max-width: @screen-xs-max) {
				display: none;
			}
		}
	}

	.file-box {
		width: 100%;

		.file {
			overflow: hidden;
			margin-bottom: 10px;

			.file-name {
				background-color: inherit;
			}

			.title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
			}

			.icon {
				float: left;
				height: 50px;		
				width: 50px;
				margin-right: 14px;	
				
				i {
					font-size: 30px;
				}	
			}
		}
	}
}

.sb-home{
	.dashboard-header .list-group-item span.label {
		display: inline-block;
		margin-bottom: 3px;
	}

	#upcoming-programs {
		margin-top: 14px;
	}

	.alert {
		p {
		    margin-bottom: 7px;
		}
	}

	#acknowledge {
		display: none;
	}
}

.sb-mentor-scripts {
	section {
		border-bottom: 1px solid #ddd;
		padding:14px 0;
	}

	form {
		h2 {
			margin-top: 14px;
		}
	}	

	.box {
		// border:1px solid #ddd;
		.well;
	}

	label {
		font-weight: normal;
	}

	.form-actions {
		padding:20px 0;
		text-align: center;

		.btn {
			min-width: 25%;

			@media (max-width: @screen-sm) {
				display: block;
				width: 100%;
				margin: 0;
			}
		}
	}

	span.brand {
		text-transform: uppercase;
	}

	.state {
		height: 34px;
	}

	table[role='presentation'] {
		input[type='radio'], input[type='checkbox'] {
			margin-right: 5px;
		}

		td {
			padding-right: 14px;
		}
	}
	
}

.sb-expenses {
	.col-ffs {
		background: rgba(99, 177, 229, 0.1);
	}
	.col-expenses {
		background: rgba(254, 209, 0, 0.1);
	}

	.footable .label, .footable .badge {
		display: inline-block;
		min-width: 70px;
	}
	.currency, .total {
		text-align: right;
		// font-weight: bold;
		padding:14px;
	}

	.total {
		font-size: 16px;

		label {
			font-weight: normal;
		}
		.total-amount {
			display: inline-block;
			margin-left:14px;
		}
	}

	#tbl-expenses {
		tbody {
			td {
				font-size: 16px;
			}
		}
		.status-payment {
			display: block;
			font-weight: bold;
			font-size: 12px;
			text-transform: lowercase;
		}
		.status-in-process{
			color:orange;
		}
		.status-paid{
			color:green;
		}
	}

	.new-expense .actions {
		text-align: right;
		border-top: 1px solid #ddd;
		padding-top: 14px;
		margin-top: 18px;
	}

}