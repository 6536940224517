.speaker {
  .ibox-content {
    .table {
      tr:first-child {
        td {
          border-top: none;
        }
      }

      td:first-child {
        text-align: right;
        width: 220px;
      }
    }
  }
}

.topic-filter {
  width: 100%;

  .btn.dropdown-toggle {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

.sb-speakers {
  .brands {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 5px;
    bottom: 5px;

    li {
      margin-left: 4px;
      float: left;
    }

    .dot {
      vertical-align: bottom;
      display: inline-block;
      border-radius: 50%;
      width: 14px;
      height: 14px;

      &.endometrin {
        background: #c40063;
      }

      &.menopur {
        background: #007f7b;
      }
    }
  }

  .legend {
    position: absolute;
    right: 0;
    top: 0;
    bottom: auto;
    width: 300px;

    li {
      line-height: 1em;
      float: right;
    }
  }
}

.kols {
  background: #e7eaec;

  h3 {
    margin-bottom: 4px;
  }

  .contact-box {
    padding: 0;
    position: relative;

    &.active,
    &:hover {
      background: #f9f9f9;
    }

    a {
      padding: 14px 0;
      display: block;
      height: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      display: inline-block;
    }
  }

  .img-circle {
    max-width: 100%;
    max-height: 70px;
  }

  p {
    margin-bottom: 0;
  }
}

.kol-profile {
  border: 3px solid #fff;

  .img-responsive {
    width: 100%;
  }
}

.profile-content {
  padding: 12px;

  p {
    margin-bottom: 0;
  }
}

.kol-right {
  .trained-presentations {
    .feed-element {
      padding-top: 7px;

      strong {
        line-height: 1.2em;
      }

      & + .feed-element {
        border-top: 1px solid #e7eaec;
      }
    }
  }

  .collapsed {
    .ibox-content {
      display: none;
    }

    .fa-chevron-down {
      transform: rotate(270deg);
    }
  }

  .ibox {
    margin-bottom: 14px;
  }

  .kol-bio {
    // margin-bottom:0;
  }

  .ibox-tools {
    // display: none;
  }

  .ibox-title {
    padding-top: 10px;
    height: auto;
    overflow: hidden;
    h5 {
      max-width: 90%;
    }
  }

  .associations {
    .feed-element {
      margin: 0;
      border: none;
      padding-bottom: 7px;
    }
  }

  .engagement {
    .feed-element {
      margin-left: 14px;
    }
  }

  .fa-check {
    color: rgb(90, 211, 90);
    font-size: 18px;
  }

  .kol-timeline {
    // margin-top:25px;
    .timeline-item .date {
      width: 120px;
    }

    .inspinia-timeline {
      padding: 0;

      h4 {
        margin: 14px 0;
        background: #f8f8f8;
        padding: 4px 14px;
        border-bottom: 1px solid #d0d0d0;

        & + .timeline-item {
          border-top: none;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      .content:hover {
        background: none;
      }
    }

    .content {
      font-size: 12px;
      padding: 10px;

      .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        em {
          color: #adadad;
        }
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        padding: 2px 0;

        a {
          color: #000;
        }
      }
    }

    .available {
      .fa {
        .btn-primary;
      }
    }
  }
}

.profile-detail {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.profile-actions {
  margin-bottom: 14px;
  .btn {
    margin: 0 3px;
  }
}
.note-editor {
  .btn-sm {
    height: 30px;
  }
  .dropdown-menu {
    background: #fff !important;
  }
}
