@media print {
  #meetings-filter-container{
    display: none;
  }

  .navbar{
    display: block;
    text-align: center;

    #navbar{
      display: none!important;
    }

    .navbar-header{
      float: none;
      margin: 20px auto;
      display: inline-block;
    }

    .navbar-brand{
      height: 80px;
    }
  }

  a[href]:after{
    content: "";
  }

  .breadcrumb{
    display: none;
  }

  .page-heading{
    border-bottom:none !important;
    padding-bottom: 0;
  }
}
