body.ipad-fix {
  padding-top: 20px;

  #white-nav-bar {
    display: block;
  }
}

body {
  //Override bootstrap error color
  .has-error .help-block,
  .has-error .control-label,
  .has-error .radio,
  .has-error .checkbox,
  .has-error .radio-inline,
  .has-error .checkbox-inline,
  .has-error.radio label,
  .has-error.checkbox label,
  .has-error.radio-inline label,
  .has-error.checkbox-inline label {
    color: #ed5565;
  }

  .page-heading.border-bottom {
    border-bottom: none !important;
  }

  .media-body {
    width: 100%;
  }

  .widget-button {
    &:hover {
      text-decoration: none !important;
    }

    .profile-widget {
      background: #327a88;
      color: #fff;
    }
    .programs-widget {
      background: #e25a33;
      color: #fff;
    }
    .training-widget {
      background: #60a4b5;
      color: #fff;
    }
  }

  background: #f3f3f4;
  font-weight: normal;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  pre {
    display: inline;
    padding: 0;
    margin: 0;
    font-size: inherit;
    line-height: 1.2em;
    background: none;
    border: none;
    font-family: inherit;
    color: inherit;
  }

  .form-control {
    background-color: #f9f9ff;
  }

  #page-wrapper {
    min-height: initial;
    padding-top: @header-height;
    z-index: 1;
  }

  &.top-navigation .wrapper.wrapper-content {
    padding: 20px 0;
  }

  &.top-navigation .nav > li a:hover,
  &.top-navigation .nav > li a:focus {
    color: #676a6c;
  }

  .page-heading {
    box-shadow: 0 -6px 30px -20px #999 inset;

    h2 {
      margin-bottom: 5px;
    }
  }

  .ibox-title {
    // border-top-color: #A70448;
    h5 {
      text-transform: uppercase;

      a {
        color: #676a6c;
        &:hover {
          text-decoration: underline;
        }

        &.btn-primary {
          color: #fff;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .mobile-dropdown {
    margin-top: 14px;

    btn {
      padding: 2px 0;
    }
  }

  .dropdown-menu {
    a {
      padding: 6px 10px;
      text-transform: none;
      font-size: 13px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .wrapper-content {
    padding-left: 0;
    padding-right: 0;
  }

  .forum-container .btn {
    @media (max-width: 768px) {
      float: left !important;
      margin-left: 50px;
      margin-top: 14px;
    }
  }

  .meeting-kol {
    line-height: 1.2em;
    margin-bottom: 3px;
    padding-top: 3px;
  }

  .popover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .description {
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      margin-bottom: 8px;
      display: block;
    }

    .date {
      font-weight: bold;
    }
    .location {
      font-style: italic;
    }
    .attendees {
      border-top: 1px solid #ddd;
      font-size: 12px;
      padding-top: 4px;
      margin-top: 8px;
    }
  }

  #eventModal {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .date {
      font-weight: bold;
    }

    .attendees {
      border-top: 1px solid #f0f0f0;
      padding-top: 7px;
      margin-top: 7px;
    }

    .modal-body {
      padding-bottom: 10px;
    }
    .modal-footer {
      border: none;
      margin: 0;
    }
  }

  .loginscreen.middle-box {
    margin-top: 0;
    top: 50px;
  }

  .status-submitted {
    background-color: @color-submitted;
    color: #fff;
  }
  .status-pending {
    background-color: @color-pending;
    color: #fff;
  }
  .status-approved {
    background-color: @color-approved;
    color: #fff;
  }
  .status-cancelled {
    background-color: @color-cancelled;
    color: #fff;
  }
  .status-denied {
    background-color: @color-denied;
    color: #fff;
  }
  .status-completed {
    background-color: @color-completed;
    color: #fff;
  }
  .status-closed {
    background-color: @color-closed;
    color: #fff;
  }
  .status-confirmed {
    background-color: @color-confirmed;
    color: #fff;
  }
  .status-in-progress {
    background-color: @color-in-progress;
    color: #fff;
  }
}

#wrapper {
  overflow-x: visible;
}

.input-group-addon,
.form-control {
  border-color: #aaa;
  background: #fcfcfa;
  box-shadow: inset 0 1px #f5f5f5;
}

.input-group-addon {
  background: #f9f9f9;
}

.m-b {
  margin-bottom: 0;
}

.page-heading {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  // color: #fff;
  overflow: hidden;

  .breadcrumb {
    background: none;
    white-space: nowrap;
  }
}

.hr-line-dashed.heavy {
  border-color: #999;
  margin: 40px 0;
}

.sidebar-content .wrapper.wrapper-events {
  padding: 20px 0 0 280px;
  padding-right: 0 !important;
}

.sidebard-panel {
  right: auto;
  left: 0;
  padding-top: 20px;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: 769px) {
    width: 280px;
  }

  .meetings-filter {
    text-align: center;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #ddd;
  }

  .m-t-md {
    margin: 0;
  }
}

.btn-outline {
  background: #fff;
}

.timeline-item {
  .content {
    border-color: #d0d0d0;
    min-height: 70px;
  }

  .date {
    text-align: right;
    width: 33.33333333%;
    position: relative;
    padding: 30px 10px 0 0;
  }
}

.badge-kol {
  background-color: @kol-red;
  color: #fff;
}

.kol-engagements {
  margin-bottom: 20px;

  .media-body {
    padding-left: 7px;
    a {
      font-weight: bold;
      color: #676a6c;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .kol {
    margin-bottom: 7px;
  }

  h3 {
    font-size: 12px;
    font-weight: 700;
    color: #676a6c;
    margin-bottom: 4px;
  }

  .feed-element {
    margin-top: 14px;
    padding-left: 8px;

    .astellas-attendees {
      font-size: 11px;
      line-height: 1em;

      strong {
        color: #c60037;
      }
    }
  }
}

.page-heading-fixed {
  position: fixed;
  z-index: 102;
  right: 0;
  left: @sidebar-width;
  margin: 0;
  transition: all 0.5s;
  overflow: visible;

  .body-small & {
    left: 0;
  }

  .mini-navbar & {
    left: @mini-sidebar-width!important;
  }
}

.forum-item {
  .badge {
    margin-top: 4px;
  }
}

.hide-nav {
  .navbar-static-side,
  #sidemenu-toggle {
    display: none;
  }
  #page-wrapper {
    margin-left: 0;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e7eaec;
  z-index: 999;
  visibility: visible;
  opacity: 0.8;
  transition: all 0.5s;

  &.ng-hide {
    visibility: hidden;
    opacity: 0;
  }

  .spinner {
    top: 100px;
  }
}

.spinner {
  margin: auto 0;
  width: 70px;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #e0e0e0;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.no-results {
  font-weight: 600;
  font-size: 20px;
  color: #e2e2e2;
  text-align: center;
}

.modal-body {
  .form-group {
    overflow: hidden;
  }
}

.addon-button {
  float: right;
}

.hide-list {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

input[type="checkbox"].update-profile {
  position: absolute;
}

//Added from style.css on January 17, 2019
.update-profile-label {
  padding-left: 1.5rem;
  font-size: 12px;
}

.btn-export {
  margin-top: -2rem;
  cursor: pointer;
}

.btn-export:hover {
  background-color: #2ba2e8;
}

.edit-expense {
  background-color: #fff;
}

/* The button */
.addeventatc {
  display: inline-block;
  position: relative;
  text-decoration: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.addeventatc .start,
.addeventatc .end,
.addeventatc .timezone,
.addeventatc .title,
.addeventatc .description,
.addeventatc .location,
.addeventatc .organizer,
.addeventatc .organizer_email,
.addeventatc .facebook_event,
.addeventatc .all_day_event,
.addeventatc .date_format,
.addeventatc .alarm_reminder,
.addeventatc .recurring,
.addeventatc .attendees,
.addeventatc .calname,
.addeventatc .uid,
.addeventatc .status,
.addeventatc .method,
.addeventatc .client,
.addeventatc .transp {
  display: none !important;
}

/* Drop down */
.addeventatc_dropdown {
  width: 230px;
  position: absolute;
  padding: 6px 0px 0px 0px;
  font-family: "Open Sans", Roboto, "Helvetica Neue", Helvetica, Optima, Segoe,
    "Segoe UI", Candara, Calibri, Arial, sans-serif;
  color: #000 !important;
  font-weight: 600;
  line-height: 100%;
  background: #fff;
  font-size: 15px;
  text-decoration: none;
  text-align: left;
  margin-left: -1px;
  display: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
  transform: scale(0.98, 0.98) translateY(5px);
  z-index: -1;
  transition: transform 0.15s ease;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.addeventatc_dropdown.topdown {
  transform: scale(0.98, 0.98) translateY(-5px) !important;
}
.addeventatc_dropdown span {
  display: block;
  line-height: 100%;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  color: #333;
  font-weight: 600;
  padding: 14px 10px 14px 55px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.addeventatc_dropdown span:hover {
  background-color: #f4f4f4;
  color: #000;
  text-decoration: none;
  font-size: 15px;
}
.addeventatc_dropdown em {
  color: #999 !important;
  font-size: 12px !important;
  font-weight: 400;
}
.addeventatc_dropdown .frs a {
  background: #fff;
  color: #cacaca !important;
  cursor: pointer;
  font-size: 9px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 110% !important;
  padding-left: 10px;
  position: absolute;
  right: 10px;
  text-align: right;
  text-decoration: none;
  top: 5px;
  z-index: 101;
}
.addeventatc_dropdown .frs a:hover {
  color: #999 !important;
}
.addeventatc_dropdown .ateappleical {
  background: url(https://www.addevent.com/gfx/icon-apple-t5.svg) 18px 40%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ategoogle {
  background: url(https://www.addevent.com/gfx/icon-google-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateoutlook {
  background: url(https://www.addevent.com/gfx/icon-outlook-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateoutlookcom {
  background: url(https://www.addevent.com/gfx/icon-outlookcom-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .ateyahoo {
  background: url(https://www.addevent.com/gfx/icon-yahoo-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .atefacebook {
  background: url(https://www.addevent.com/gfx/icon-facebook-t5.svg) 18px 50%
    no-repeat;
  background-size: 22px 100%;
}
.addeventatc_dropdown .copyx {
  height: 21px;
  display: block;
  position: relative;
  cursor: default;
}
.addeventatc_dropdown .brx {
  height: 1px;
  overflow: hidden;
  background: #e8e8e8;
  position: absolute;
  z-index: 100;
  left: 10px;
  right: 10px;
  top: 9px;
}
.addeventatc_dropdown.addeventatc-selected {
  transform: scale(1, 1) translateY(0px);
  z-index: 99999999999;
}
.addeventatc_dropdown.topdown.addeventatc-selected {
  transform: scale(1, 1) translateY(0px) !important;
}
.addeventatc_dropdown .drop_markup {
  background-color: #f4f4f4;
}

.sb-attendee-view-modal-body {
  .row + .row {
    margin-top: 14px;
  }

  .field-value {
    font-weight: bold;
  }
}

.requires-submitted {
  display: none;

  &.has-submitted {
    display: block;
  }
}

.sb-consultant-engagements {
  .engagement-name {
    text-align: left !important;
  }
}
