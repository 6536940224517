
@media (max-width: 350px) {
	.timeline-item .date {
		padding-top:14px;
		
		i {
			top: -14px;			
		}		
	}
}

@media (max-width: @screen-sm-max) {

}

@media (max-width: @screen-lg) {
	.celgene-ee{
		.events-sidebar-right{
		}

		#calendar-container .affix{
			// right:15px;			
		}

		.fc button{
			padding:0 0.4em;
		}

		.fc-toolbar{
			h2{
				margin-bottom: 7px;
			}
		}
	}	
}

@media (max-width: 1000px) {
	.welcome-message {
		display: none;
	}

	.sidebar-content .wrapper-content, .wrapper.sidebar-content {
		padding-left: 280px !important;
		padding-right:0 !important;
	}
}