.setStatusColors(
  @activity: #717171;
  @approved: @color-approved;
  @cancelled: @color-cancelled;
  @closed: @color-closed;
  @completed: @color-completed;
  @confirmed: @color-confirmed;
  @denied: @color-denied;
  @in-progress: @color-in-progress;
  @pending: @color-pending;
  @submitted: @color-submitted) {
  .submitted .label,
  .label.label-submitted,
  .status-submitted {
    border-color: @submitted;
    background-color: @submitted;
    color: #fff;
  }
  .pending .label,
  .label.label-pending,
  .status-pending {
    border-color: @pending;
    background-color: @pending;
    color: #fff;
  }
  .approved .label,
  .label.label-approved,
  .status-approved {
    border-color: @approved;
    background-color: @approved;
    color: #fff;
  }
  .cancelled .label,
  .label.label-cancelled,
  .status-cancelled {
    border-color: @cancelled;
    background-color: @cancelled;
    color: #fff;
  }
  .denied .label,
  .label.label-denied,
  .status-denied {
    border-color: @denied;
    background-color: @denied;
    color: #fff;
  }
  .completed .label,
  .label.label-completed,
  .status-completed {
    border-color: @completed;
    background-color: @completed;
    color: #fff;
  }
  .closed .label,
  .label.label-closed,
  .status-closed {
    border-color: @closed;
    background-color: @closed;
    color: #fff;
  }
  .confirmed .label,
  .label.label-confirmed,
  .status-confirmed {
    border-color: @confirmed;
    background-color: @confirmed;
    color: #fff;
  }
  .in-progress .label,
  .label.label-in-progress,
  .status-in-progress {
    border-color: @in-progress;
    background-color: @in-progress;
    color: #fff;
  }
  .activity .label,
  .label.label-activity,
  .status-activity {
    border-color: @activity;
    background-color: @activity;
    color: #fff;
  }

  .meeting {
    //Status colors
    &.activity {
      border-color: @activity;
      .ibox-tools .label {
        background-color: @activity;
      }
    }
    &.submitted {
      border-color: @submitted;
      .ibox-tools .label {
        background-color: @submitted;
      }
    }
    &.pending {
      border-color: @pending;
      .ibox-tools .label {
        background-color: @pending;
      }
    }
    &.approved {
      border-color: @approved;
      .ibox-tools .label {
        background-color: @approved;
      }
    }
    &.cancelled {
      border-color: @cancelled;
      .ibox-tools .label {
        background-color: @cancelled;
      }
    }
    &.denied {
      border-color: @denied;
      .ibox-tools .label {
        background-color: @denied;
      }
    }
    &.completed {
      border-color: @completed;
      .ibox-tools .label {
        background-color: @completed;
      }
    }
    &.closed {
      border-color: @closed;
      .ibox-tools .label {
        background-color: @closed;
      }
    }
    &.confirmed {
      border-color: @confirmed;
      .ibox-tools .label {
        background-color: @confirmed;
      }
    }
    &.in-progress {
      border-color: @in-progress;
      .ibox-tools .label {
        background-color: @in-progress;
      }
    }
  }
}

.setStatusColors();

.shire-obu {
  .navbar-brand {
    overflow: hidden;

    img {
      // max-height: none;
      // height: 100px;
      margin-top: 12px;

      // @media (max-width: 768px) {
      // 	height: 80px;
      // 	margin-top: -16px;
      // }
    }
  }

  .navbar-top-links li:last-child {
    @media (max-width: 768px) {
      margin-right: 0px;
    }
  }

  .meeting {
    &.live-in-venue-program {
      border-color: #280091;
    }

    &.virtual-program {
      border-color: #fed100;
    }

    &.virtual-add-on-program {
      border-color: #63b1e5;
    }
  }

  .format-live-in-venue-program {
    background-color: #280091;
    border-color: #280091;
  }
  .format-virtual-program {
    background-color: #fed100;
    border-color: #fed100;
  }
  .format-virtual-add-on-program {
    background-color: #63b1e5;
    border-color: #63b1e5;
  }
}

.ferring-rh {
  footer {
    position: absolute;
    bottom: 14px;
    left: 14px;
    color: #dfe4ed;
    font-size: 10px;

    .approved-number {
      margin-top: 7px;
      display: inline-block;
    }
  }

  &.sb-login {
    footer {
      color: #000;
    }
  }

  &.mini-navbar {
    footer {
      display: none;
    }
  }

  .meeting {
    &.live-in-office-program {
      border-color: #c6775c;
    }

    &.live-in-venue-program {
      border-color: #ba5836;
    }

    &.virtual-program {
      border-color: #579cd3;
    }
  }

  .format-live-in-office-program {
    background-color: #c6775c;
    border-color: #c6775c;
  }
  .format-live-in-venue-program {
    background-color: #ba5836;
    border-color: #ba5836;
  }
  .format-virtual-program {
    background-color: #579cd3;
    border-color: #579cd3;
  }
}

.ferring {
  footer {
    position: absolute;
    bottom: 14px;
    left: 14px;
    color: #dfe4ed;
    font-size: 10px;

    .approved-number {
      margin-top: 7px;
      display: inline-block;
    }
  }

  &.sb-login {
    footer {
      color: #000;
    }
  }

  &.mini-navbar {
    footer {
      display: none;
    }
  }

  .meeting {
    border-color: #579cd3 !important;
  }

  .format-live-in-office-program {
    background-color: #c6775c;
    border-color: #c6775c;
  }
  .format-live-in-venue-program {
    background-color: #ba5836;
    border-color: #ba5836;
  }
  .format-virtual-program {
    background-color: #579cd3;
    border-color: #579cd3;
  }
}

.shire-bax {
  .navbar-static-top .navbar-brand img {
    max-height: 40px;
  }

  .contact-message {
    .contact {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      a {
        font-weight: bold;
      }
    }
  }

  .page-speakers {
    .page-heading {
      padding-top: 20px;
    }

    .banner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: #004f7d;
      color: #fff;
      padding: 6px 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: 200;
      line-height: 1em;
    }
  }

  .meeting .meeting-description em {
    display: block;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    text-align: center;

    p {
      color: #dfe4ed;
      margin-bottom: 0;
      font-size: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
      width: 90px;
      margin-top: 7px;
    }
  }

  &.sb-login {
    footer {
      img {
        width: 160px;
      }
    }
  }
}

.lundbeck {
  #lundbeck-speaker-details ul {
    margin: 14px 0 0;
    padding: 0;
    list-style: none;
  }

  .multi-select {
    height: auto !important;
  }

  .setStatusColors(
    @approved: #4DB0C0; @cancelled: #D02424; @closed: #775496; @completed:
      #4EBA29; @confirmed: #4DB0C0; @denied: #D02424; @in-progress: #4DB0C0;
      @pending: #999; @submitted: #999
  );

  .list-group-item:first-child {
    border-top: none !important;
  }

  .profile-content {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  footer {
    position: absolute;
    bottom: 4px;
    color: #fff;
    left: 4px;
    max-width: 100%;
    padding: 4px;

    img {
      width: 100px;
      display: block;
    }

    .mini-navbar& {
      display: none;
    }
  }

  &.sb-portal {
    footer {
      max-width: 100%;
      padding: 4px;

      img {
        width: 150px;
        display: block;
      }
    }

    .meeting.activity {
      .meeting-time,
      .meeting-attendees {
        display: none;
      }
    }
  }
}

.sb-login .logo-360-connex {
  width: 100px;
  vertical-align: bottom;
}

.sb-profiles-contracts {
  .contract-status {
    text-align: left !important;
    width: auto !important;
  }
}
