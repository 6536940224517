.sidebard-panel {
  width: 220px;
  background: darken(@gray, 3%);
  padding: 10px 20px;
  position: absolute;
  right: 0;
}

.sidebard-panel .feed-element img.img-circle {
  width: 32px;
  height: 32px;
}

.sidebard-panel .feed-element, .media-body, .sidebard-panel p {
  font-size: 12px;
}

.sidebard-panel .feed-element {
  margin-top: 20px;
  padding-bottom: 0;
}
.sidebard-panel .list-group {
  margin-bottom: 10px;
}
.sidebard-panel .list-group .list-group-item {
  padding: 5px 0;
  font-size: 12px;
  border:0;
}

.sidebar-content .wrapper, .wrapper.sidebar-content {
  padding-right: 230px !important;
}

.body-small .sidebar-content .wrapper, .body-small .wrapper.sidebar-content {
  padding-right: 20px !important;
}

