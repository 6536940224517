/* TOOGLE SWICH */

.onoffswitch {
  position: relative;
  width: 64px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid @navy;
  border-radius: 2px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: @navy;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #FFFFFF;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  width: 20px;
  margin: 0px;
  background: #FFFFFF;
  border: 2px solid @navy;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* CHOSEN PLUGIN */

.chosen-container-single .chosen-single {
  background: #ffffff;
  box-shadow: none;
  -moz-box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #CBD5DD;
  border-radius: 2px;
  cursor: text;
  height: auto !important;
  margin: 0;
  min-height: 30px;
  overflow: hidden;
  padding: 4px 12px;
  position: relative;
  width: 100%;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background: #f1f1f1;
  border: 1px solid #ededed;
  border-radius: 2px;
  box-shadow: none;
  color: #333333;
  cursor: default;
  line-height: 13px;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  position: relative;
}

/* PAGINATIN */

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #f4f4f4;
  border-color: #DDDDDD;
  color: inherit;
  cursor: default;
  z-index: 2;
}

.pagination > li > a, .pagination > li > span {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  color: inherit;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 4px 10px;
  position: relative;
  text-decoration: none;
}

/* TOOLTIPS */

.tooltip-inner {
  background-color: #2F4050;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #2F4050;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #2F4050;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #2F4050;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #2F4050;
}

/* EASY PIE CHART*/

.easypiechart {
  position: relative;
  text-align: center;
}

.easypiechart .h2 {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
}

.easypiechart canvas {
  top: 0;
  left: 0;
}

.easypiechart .easypie-text {
  line-height: 1;
  position: absolute;
  top: 33px;
  width: 100%;
  z-index: 1;
}

.easypiechart img {
  margin-top: -4px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* FULLCALENDAR */

.fc-state-default {
  background-color: #ffffff;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: #333333;
  text-shadow: none;
}

.fc-state-default {
  border: 1px solid;
}

.fc-button {
  color: inherit;
  border: 1px solid @border-color;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap;
}

.fc-state-active {
  background-color: @dark-gray;
  border-color: @dark-gray;
  color: #ffffff;
}

.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}

.fc-content .fc-widget-header, .fc-content .fc-widget-content {
  border-color: @border-color;
  font-weight: normal;
}

.fc-border-separate tbody {
  background-color: #F8F8F8;
}

.fc-state-highlight {
  background: none repeat scroll 0 0 #FCF8E3;
}

.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}

.fc-ltr .fc-event-hori.fc-event-end, .fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px;
}

.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: @navy; /* background color */
  border-color: @navy; /* border color */
}

.fc-event-time, .fc-event-title {
  color: #717171;
  padding: 0 1px;
}

.ui-calendar .fc-event-time, .ui-calendar .fc-event-title {
  color: #fff;
}


/* Chat */
.chat-activity-list .chat-element {
  border-bottom: 1px solid @border-color;
}

.chat-element:first-child {
  margin-top: 0;
}

.chat-element {
  padding-bottom: 15px;
}

.chat-element, .chat-element .media {
  margin-top: 15px;
}

.chat-element, .media-body {
  overflow: hidden;
}

.media-body {
  display: block;
}

.chat-element > .pull-left {
  margin-right: 10px;
}

.chat-element img.img-circle, .dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.chat-element .well {
  border: 1px solid @border-color;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.chat-element .actions {
  margin-top: 10px;
}

.chat-element .photos {
  margin: 10px 0;

}

.right.chat-element > .pull-right {
  margin-left: 10px;
}

.chat-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #B3A9A9;
}

.chat li.left .chat-body {
  margin-left: 60px;
}

.chat li.right .chat-body {
  margin-right: 60px;
}

.chat li .chat-body p {
  margin: 0;
  color: #777777;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
  margin-right: 5px;
}

.chat-panel .panel-body {
  height: 350px;
  overflow-y: scroll;
}

/* LIST GROUP */

a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
  background-color: @navy;
  border-color: @navy;
  color: #FFFFFF;
  z-index: 2;
}

.list-group-item-heading {
  margin-top: 10px;
}

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  font-size: 12px;
  line-height: inherit;
}

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}

.no-padding .list-group {
  margin-bottom: 0;
}

.list-group-item {
  background-color: inherit;
  border: 1px solid @border-color;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}

/* FLOT CHART  */

.flot-chart {
  display: block;
  height: 200px;
}

.widget .flot-chart.dashboard-chart {
  display: block;
  height: 120px;
  margin-top: 40px;
}

.flot-chart.dashboard-chart {
  display: block;
  height: 180px;
  margin-top: 40px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.flot-chart-pie-content {
  width: 200px;
  height: 200px;
  margin: auto;
}

.jqstooltip {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  visibility: hidden;
  background: rgb(43, 48, 58);
  background-color: rgba(43, 48, 58, 0.8);
  color: white;
  text-align: left;
  white-space: nowrap;
  z-index: 10000;
  padding: 5px 5px 5px 5px;
  min-height: 22px;
  border-radius: 3px;
}

.jqsfield {
  color: white;
  text-align: left;
}

.h-200 {
  min-height: 200px;
}

.legendLabel {
  padding-left: 5px;
}

.stat-list li:first-child {
  margin-top: 0;
}

.stat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stat-percent {
  float: right;
}

.stat-list li {
  margin-top: 15px;
  position: relative;
}

/* DATATABLES */

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

table.dataTable thead .sorting_asc:after {
  float: right;
  font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: fontawesome;
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, .5);
}

.dataTables_wrapper {
  padding-bottom: 30px;
}

/* CIRCLE */

.img-circle {
  border-radius: 50%;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}

.show-grid {
  margin: 15px 0;
}

/* ANIMATION */

.css-animation-box h1 {
  font-size: 44px;
}

.animation-efect-links a {
  padding: 4px 6px;
  font-size: 12px;
}

#animation_box {
  background-color: #f9f8f8;
  border-radius: 16px;
  width: 80%;
  margin: 0 auto;
  padding-top: 80px;
}

.animation-text-box {
  position: absolute;
  margin-top: 40px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
}

.animation-text-info {
  position: absolute;
  margin-top: -60px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  font-size: 10px;
}

.animation-text-box h2 {
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 5px;
}

.animation-text-box p {
  font-size: 12px;
  text-transform: uppercase;
}

/* PEACE */

.pace .pace-progress {
  background: @navy;
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: @sidebar-width;
  height: 2px;

  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.body-small .pace .pace-progress {
  left: 0px;
}

.pace-inactive {
  display: none;
}

/* WIDGETS */

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.widget.style1 h2 {
  font-size: 30px;
}

.widget h2, .widget h3 {
  margin-top: 5px;
  margin-bottom: 0;
}

.widget-text-box {
  padding: 20px;
  border: 1px solid @border-color;
  background: #ffffff;
}

.widget-head-color-box {
  border-radius: 5px 5px 0px 0px;
  margin-top: 10px;

}

.widget .flot-chart {
  height: 100px;
}

.vertical-align div {
  display: inline-block;
  vertical-align: middle;
}

.vertical-align h2, .vertical-align h3 {
  margin: 0;
}

.todo-list {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.todo-list.small-list {
  font-size: 12px;
}

.todo-list.small-list > li {
  background: @gray;
  border-left: none;
  border-right: none;
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 6px 6px 6px 12px;
}

.todo-list.small-list .btn-xs, .todo-list.small-list .btn-group-xs > .btn {
  border-radius: 5px;
  font-size: 10px;
  line-height: 1.5;
  padding: 1px 2px 1px 5px;
}

.todo-list > li {
  background: @gray;
  border-left: 6px solid @border-color;
  border-right: 6px solid @border-color;
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 10px;
}

.todo-list .handle {
  cursor: move;
  display: inline-block;
  font-size: 16px;
  margin: 0 5px;
}

.todo-list > li .label {
  font-size: 9px;
  margin-left: 10px;
}

.check-link {
  font-size: 16px;
}

.todo-completed {
  text-decoration: line-through;
}

.geo-statistic h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.glyphicon.fa {
  font-family: "FontAwesome";
}
/* INPUTS */
.inline {
  display: inline-block !important;
}

.input-s-sm {
  width: 120px;
}

.input-s {
  width: 200px;
}

.input-s-lg {
  width: 250px;
}

.i-checks {
  padding-left: 0;
}

.form-control, .single-line {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size:14px
}

.form-control:focus, .single-line:focus {
  border-color: @navy;
}

.has-success .form-control {
  border-color: @navy;
}

.has-warning .form-control {
  border-color: @yellow;
}

.has-error .form-control {
  border-color: @red;
}

.has-success .control-label {
  color: @navy;
}

.has-warning .control-label {
  color: @yellow;
}

.has-error .control-label {
  color: @red;
}

.input-group-addon {
  background-color: #fff;
  border: 1px solid #E5E6E7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

.spinner-buttons.input-group-btn .btn-xs {
  line-height: 1.13;
}

.spinner-buttons.input-group-btn {
  width: 20%;
}

.noUi-connect {
  background: none repeat scroll 0 0 @navy;
  box-shadow: none;
}

.slider_red .noUi-connect {
  background: none repeat scroll 0 0 @red;
  box-shadow: none;
}

/* UI Sortable */

.ui-sortable .ibox-title {
  cursor: move;
}

.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #e7eaec;
}

.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important;
}

/* SWITCHES */
.onoffswitch {
  position: relative; width: 54px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block; overflow: hidden; cursor: pointer;
  border: 2px solid #1AB394; border-radius: 3px;
}
.onoffswitch-inner {
  display: block; width: 200%; margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s; -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s; transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block; float: left; width: 50%; height: 16px; padding: 0; line-height: 16px;
  font-size: 10px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 7px;
  background-color: #1AB394; color: #FFFFFF;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 7px;
  background-color: #FFFFFF; color: #919191;
  text-align: right;
}
.onoffswitch-switch {
  display: block; width: 18px; margin: 0px;
  background: #FFFFFF;
  border: 2px solid #1AB394; border-radius: 3px;
  position: absolute; top: 0; bottom: 0; right: 36px;
  -moz-transition: all 0.3s ease-in 0s; -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s; transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* jqGrid */


.ui-jqgrid{
  -moz-box-sizing: content-box;
}
.ui-jqgrid-btable{
  border-collapse: separate;
}
.ui-jqgrid-htable{
  border-collapse: separate;
}
.ui-jqgrid-titlebar{
  height: 40px;
  line-height: 15px;
  color: #676a6c;
  background-color: #F9F9F9;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.ui-jqgrid .ui-jqgrid-title {
  float: left;
  margin: 1.1em 1em 0.2em;
}
.ui-jqgrid .ui-jqgrid-titlebar {
  position: relative;
  border-left: 0px solid;
  border-right: 0px solid;
  border-top: 0px solid;
}
.ui-widget-header{
  background: none;
  background-image: none;
  background-color: #f5f5f6;
  text-transform: uppercase;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.ui-jqgrid tr.ui-row-ltr td {
  border-right-color: inherit;
  border-right-style: solid;
  border-right-width: 1px;
  text-align: left;
  border-color: #DDDDDD;
  background-color: inherit;
}
.ui-search-toolbar input[type="text"]{
  font-size: 12px;
  height: 15px;
  border: 1px solid #CCCCCC;
  border-radius: 0px;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: #F9F9F9;
  border: 1px solid #DDDDDD;
  line-height: 15px;
  font-weight: bold;
  color: #676a6c;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.ui-widget-content {
  box-sizing: content-box;
}
.ui-icon-triangle-1-n {
  background-position: 1px -16px;
}
.ui-jqgrid tr.ui-search-toolbar th {
  border-top-width: 0px !important;
  border-top-color: inherit !important;
  border-top-style: ridge !important
}
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background: #f5f5f5;
  border-collapse: separate;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: #f2fbff;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 1px solid #dddddd;
  background: #ffffff;
  font-weight: normal;
  color: #212121;
}
.ui-jqgrid .ui-pg-input {
  font-size: inherit;
  width: 50px;
  border: 1px solid #CCCCCC;
  height: 15px;
}
.ui-jqgrid .ui-pg-selbox {
  display: block;
  font-size: 1em;
  height: 25px;
  line-height: 18px;
  margin: 0;
  width: auto;
}
.ui-jqgrid .ui-pager-control {
  position: relative;
}
.ui-jqgrid .ui-jqgrid-pager {
  height: 32px;
  position: relative;
}
.ui-pg-table .navtable .ui-corner-all{
  border-radius: 0px;
}
.ui-jqgrid .ui-pg-button:hover {
  padding: 1px;
  border: 0px;
}
.ui-jqgrid .loading {
  position: absolute;
  top: 45%;
  left: 45%;
  width: auto;
  height: auto;
  z-index:101;
  padding: 6px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  display: none;
  border-width: 2px !important;
  font-size:11px;
}
.ui-jqgrid .form-control {
  height: 10px;
  width: auto;
  display: inline;
  padding: 10px 12px;
}
.ui-jqgrid-pager {
  height: 32px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 0;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 0;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 0;
}

.ui-widget-content {
  border:1px solid #ddd;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  padding: 0;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  border-bottom: 1px solid #ddd;
}

.ui-jqgrid tr.jqgrow td {
  padding: 6px;
}

.ui-jqdialog .ui-jqdialog-titlebar {
  padding: 10px 10px;

}

.ui-jqdialog .ui-jqdialog-title {
  float: none !important;
}

.ui-jqdialog > .ui-resizable-se {
  position: absolute;
}




/* Nestable list */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid #e7eaec;
  background: #f5f5f5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
  content: '-';
}

#nestable2 .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1;

}

#nestable2 .dd-item > button:before {
  content: "\f067";
}

#nestable2 .dd-item > button[data-action="collapse"]:before {
  content: "\f068";
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

/**
* Nestable Extras
*/
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0;
}

#nestable-output,
#nestable2-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
  color: inherit;
  border: 1px dashed #e7eaec;
  background: #f3f3f4;
  padding: 10px;
}

#nestable2 .dd-handle:hover {
  /*background: #bbb;*/
}


#nestable2  span.label {
  margin-right: 10px;
}

#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* CodeMirror */
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

/* Google Maps */
.google-map {
  height: 300px;
}

/* Validation */
label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}

.form-control.error {
  border: 1px dotted #cc5965;
}

/* ngGrid */
.gridStyle {
  border: 1px solid rgb(212, 212, 212);
  width: 100%;
  height: 400px
}
.gridStyle2 {
  border: 1px solid rgb(212,212,212);
  width: 500px;
  height: 300px;
}
.ngH eaderCell {
  border-right: none;
  border-bottom: 1px solid #e7eaec;
}
.ngCell {
  border-right: none;
}
.ngTopPanel {
  background: #F5F5F6;
}
.ngRow.even {
  background: #f9f9f9;
}
.ngRow.selected {
  background: #EBF2F1;
}
.ngRow {
  border-bottom: 1px solid #e7eaec;
}
.ngCell {
  background-color: transparent;
}
.ngHeaderCell {
  border-right: none;
}

/* Toastr custom style */

#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 24px;
  float: left;
  color: #FFF;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}
#toast-container > .toast-warning:before {
  content: "\f003";
}
#toast-container > .toast-error:before {
  content: "\f001";
}
#toast-container > .toast-info:before {
  content: "\f005";
}
#toast-container > .toast-success:before {
  content: "\f00C";
}

#toast-container > div {
  -moz-box-shadow: 0 0 3px #999;
  -webkit-box-shadow: 0 0 3px #999;
  box-shadow: 0 0 3px #999;
  opacity: .9;
  -ms-filter: alpha(Opacity=90);
  filter: alpha(opacity=90)
}

#toast-container > :hover {
  -moz-box-shadow: 0 0 4px #999;
  -webkit-box-shadow: 0 0 4px #999;
  box-shadow: 0 0 4px #999;
  opacity: 1;
  -ms-filter: alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer
}

.toast {
  background-color: @navy
}

.toast-success {
  background-color: @navy
}

.toast-error {
  background-color: @red
}

.toast-info {
  background-color: @lazur
}

.toast-warning {
  background-color: @yellow
}

.toast-top-full-width {
  margin-top: 20px;
}

.toast-bottom-full-width {
  margin-bottom: 20px;
}

/* Notifie */
.cg-notify-message.inspinia-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow : 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow : 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  border:none ;
  margin-top: 30px;
  color: inherit;
}

.inspinia-notify.alert-warning {
  border-left: 6px solid @yellow;
}
.inspinia-notify.alert-success {
  border-left: 6px solid @blue;
}

.inspinia-notify.alert-danger {
  border-left: 6px solid @red;
}

.inspinia-notify.alert-info {
  border-left: 6px solid @navy;
}

/* Image cropper style */
.img-container, .img-preview {
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.img-preview-sm {
  height: 130px;
  width: 200px;
}

/* Forum styles  */
.forum-post-container .media {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
  float: left;
  margin-right: 20px;
  text-align: center;
  width: 110px;
}

.forum-avatar .img-circle {
  height: 48px;
  width: 48px;
}

.author-info {
  color: #676a6c;
  font-size: 11px;
  margin-top: 5px;
  text-align: center;
}

.forum-post-info {
  padding: 9px 12px 6px 12px;
  background:#f9f9f9;
  border: 1px solid #f1f1f1;
}

.media-body > .media {
  background: #f9f9f9;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
}

.forum-post-container .media-body .photos {
  margin: 10px 0;
}

.forum-photo {
  max-width: 140px;
  border-radius: 3px;

}

.media-body > .media .forum-avatar {
  width: 70px;
  margin-right: 10px;
}
.media-body > .media .forum-avatar .img-circle {
  height: 38px;
  width: 38px;
}

.mid-icon {
  font-size: 66px;
}

.forum-item {
  margin: 10px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.views-number {
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
}

.forum-container, .forum-post-container {
  padding: 30px !important;
}

.forum-item small {
  color: #999;
}

.forum-item .forum-sub-title{
  color: #999;
  margin-left: 50px;
}

.forum-title {
  margin: 15px 0 15px 0;
}

.forum-info {
  text-align: center;
}

.forum-desc {
  color: #999;
}

.forum-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}

a.forum-item-title {
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

a.forum-item-title:hover {
  color: inherit;
}

.forum-icon .fa {

  font-size: 30px;
  margin-top: 8px;
  color: #9b9b9b;
}
.forum-item.active .fa {
  color: @navy;
}

.forum-item.active a.forum-item-title {
  color: @navy;
}

@media (max-width: 992px) {

  .forum-info {
    margin: 15px 0 10px 0px;

    /* Comment this is you want to show forum info in small devices */
    display: none;
  }

  .forum-desc {
    float: none !important;
  }

}

/* New Timeline style */

.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

#vertical-timeline {
  position: relative;
  padding:0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}


.vertical-timeline-content .btn {
  float: right;
}

#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}


.dark-timeline .vertical-timeline-content:before
{
  border-color: transparent #f5f5f5 transparent transparent ;
}

.dark-timeline.center-orientation .vertical-timeline-content:before
{
  border-color: transparent  transparent transparent #f5f5f5;
}

.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before
{
  border-color: transparent #f5f5f5 transparent transparent;
}

.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content
{
  background: #f5f5f5;
}

@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px;
  }
}

@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-block:first-child {
  margin-top: 0;
}
.vertical-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
  }
  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0;
  }
  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center;
}
.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
  }
  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px;
  }
  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden;
  }
}


.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}
.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}


.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}


.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500;
}

.vertical-date small {
  color: @navy;
  font-weight: 400;
}

.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }
  .vertical-timeline-content p {
    font-size: 13px;
  }

}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }

  .center-orientation .vertical-timeline-content .btn {
    float: left;
  }
  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden;
  }
}